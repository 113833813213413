import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getToken() {
    const accessToken = localStorage.getItem('token')
    const base64 = accessToken?.split('.')[1]
    return base64 && JSON.parse(atob(base64))
  }

  getTypeUser() {
    const token = this.getToken()
    return token?.type
  }

  userIsType(type: 'EXECUTIVE' | 'PUBLISHER') {
    return type === this.getTypeUser()
  }
}
