import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {RealStateRepository} from "../repositories/real-state.repository";
import {RealStateModel} from "../domain/real-state.model";
import { PublisherBaseModel } from "../domain/publisher-base-model";
import { RealModel } from "../domain/real_model";


@Injectable({
    providedIn: "root"
})
export class RealStateUseCaseService {

    constructor(
        private _realStateRepository: RealStateRepository
    ) {
    }

    get(): Observable<RealStateModel[]> {
        return this._realStateRepository.get()
    }
    getPublisher(): Observable<RealModel> {
        return this._realStateRepository.getPublisher()
    }
}