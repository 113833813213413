import {Observable} from 'rxjs';
import {RealStateModel} from "../domain/real-state.model";

import { PublisherBaseModel } from '../domain/publisher-base-model';
import { RealModel } from '../domain/real_model';

export abstract class RealStateRepository {
    abstract get(): Observable<RealStateModel[]>;
    abstract getPublisher(): Observable<RealModel>;
}
