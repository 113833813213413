import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleMapComponent} from './google-map/google-map.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {HttpClientJsonpModule} from "@angular/common/http";
import {MaterialModule} from "../modules/material.module";
import {LnComponentsModule} from "../components/ln-customs-material-components/ln-components.module";


@NgModule({
    declarations: [
        GoogleMapComponent,
        
    ],
    exports: [
        GoogleMapComponent,
        GoogleMapsModule
    ],
    imports: [
        CommonModule,
        GoogleMapsModule,
        HttpClientJsonpModule,
        MaterialModule,
        LnComponentsModule,
    ]
})
export class LnGoogleMapModule {
}
