<nav class="bg-white border-gray-200 rounded header">
    <div class="flex flex-wrap items-center justify-between mx-auto">
        <div class="flex">
            <a href="/" class="flex items-center cursor-pointer">
            <img src="../../../../assets/icons/logo.svg" class="h-16" alt="Logo" />
        </a>
        </div>
        <ng-container *ngIf="show">
            <div class="flex flex-wrap items-center">
                <div class="flex items-center md:order-2 ml-3.5">
<!--                    <ln-translate class="mr-3 md:hidden"/>-->
                    <button type="button" class="inline-flex items-center p-2 text-sm rounded-lg md:hidden w-auto"
                        (click)="showMenuMobile()"><span class="sr-only">Abrir menu principal</span>
                        <mat-icon aria-hidden="false" aria-label="Example home icon">{{ icon }}</mat-icon>
                    </button>
                </div>
                <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
                    <ul
                        class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
<!--                        <li>-->
<!--                            <ln-translate/>-->
<!--                        </li>-->
                        <li *ngIf="role==='PUBLISHER'" >
                            <a [routerLink]="['my-account/my-data']"
                               class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                               aria-current="page">{{'ACCOUNT' | translate}}</a></li>
                        <li *ngIf="!isLogin">
                            <a [routerLink]="['sessions/signin']"
                                class="a-secondary-color block py-2 pl-3 pr-4 roundedmd:bg-transparent md:p-0">
                                {{'LINK_SIGN_IN' | translate}}</a>
                        </li>
                        <li *ngIf="isLogin">
                            <a (click)="openDialogCloseSession()"
                                class="a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
                                {{'LINK_LOG_OUT' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-full md:hidden">
                <div class="items-center justify-between w-full  md:flex md:w-auto menuMobile" [class.showMenu]="showMenu" id="navbar-cta">
                    <ul class="flex flex-col mt-4 md:flex-row md:mt-0 md:text-sm md:font-medium divide-y">
                        <li *ngIf="isLogin">
                            <a [routerLink]="['my-account/my-data']"
                               class="block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
                               aria-current="page">Mi Cuenta</a></li>
                        <li *ngIf="!isLogin">
                            <a [routerLink]="['sessions/register']"
                                class="a-secondary-color block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0">
                                {{'LINK_SIGN_UP' | translate}}</a>
                        </li>
                        <li *ngIf="!isLogin">
                            <a [routerLink]="['sessions/signin']"
                                class="a-secondary-color block py-2 pl-3 pr-4 rounded md:bg-transparent md:p-0">
                                {{'LINK_SIGN_IN' | translate}}</a>
                        </li>
                        <li *ngIf="isLogin">
                            <a (click)="openDialogCloseSession()"
                                class="a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
                                {{'LINK_LOG_OUT' | translate}}</a>
                        </li>
                    </ul>
                    <hr>
                </div>
            </div>
        </ng-container>
    </div>
</nav>
