export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyBeJBAckugwdEH8HzWrP7qv957f0bAscVg",
        authDomain: "sza-preprod.firebaseapp.com",
        projectId: "sza-preprod",
        storageBucket: "https://dev.staticstorage.lunanuevaportal.com",
        messagingSenderId: "359616739836",
        appId: "1:359616739836:web:000c318aa5137d15221a79"
    },
    tenantId: "dev-lunanueva-bo-jncyr",
    baseURL: "https://dev.api.backoffice.lunanuevaportal.com/api/v1/",
    endpoints: {
        session: 'session',
        cities: "cities",
        propertyTypes: "property-types",
        properties: "properties",
        publisherPosts: "posts",
        userData: "user-data",
        requirements: "requirement",
        realStates: "real-state",
        users:"user",
        log:"log"
    },
    googleMapApiKey: "AIzaSyCJx9U6UPxx8rNXXhVCdS3WK9RBvFf4HGc",
    recaptcha: {
        publicKey: "0x4AAAAAAACrvYbrkxvyQt_k"
    },
};
