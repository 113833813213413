export const locale = {
  lang: 'es',
  data: {
    LINK_SERVICES:'Servicios',
    LINK_SIGN_UP:'Crea tu cuenta',
    LINK_SIGN_IN:'Iniciar sesión',
    ACCOUNT:'Mi cuenta',
    LINK_LOG_OUT: 'Cerrar sesión',
    BUTTON:'Publicar propiedad'
  }
}
