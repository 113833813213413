<div [formGroup]="formGroup"  class="containerMap">
  <div class="divInputs">
    <span class="autoCompleteField">
      <mat-label>Dirección*</mat-label>
      <mat-form-field class="autoComplete" appearance="outline">
      <input formControlName="address" #inputField matInput > 
    </mat-form-field>
  </span>
    <span class="latLangFields" >
      <mat-label>Latitud</mat-label>
      <mat-form-field class="latLang"  appearance="outline">
        <input type="number" formControlName="latitude" #latitudeField matInput [(ngModel)]="latitude" [disabled]="disableLatLang" (keydown.enter)="$event.preventDefault(); updateMap()">
      </mat-form-field>
    </span>
    <span class="latLangFields">
      <mat-label>
        Longitud</mat-label>
      <mat-form-field class="latLang"  appearance="outline">
        <input type="number" formControlName="longitude" #longitudeField matInput [(ngModel)]="longitude" [disabled]="disableLatLang" (keydown.enter)="$event.preventDefault(); updateMap()">
      </mat-form-field>
    </span>
    <button class="buttonClear" mat-raised-button color="primary" [disabled]="" (click)="updateMap()">Buscar Por Latitud y Longitud</button>
    <button class="buttonClear" mat-raised-button color="primary" [disabled]="" (click)="resetMap()">Reestablecer Mapa</button>
  </div>
  
  

  <div #map id="map" class="mapContainer" ></div>
</div>

