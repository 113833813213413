import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {PropertyPostModel} from "../domain/property-post-model";
import {PropertyPostListInterface} from "../../shared/interfaces/property-post-list.interface";
import {AddPostDto} from "../../shared/interfaces/add-post";
import {FiltersInterface} from "../../shared/interfaces/filters.interface";

@Injectable()
export abstract class PropertyPostRepository {
    abstract getPostDetails(id: string): Observable<PropertyPostModel>;

    abstract getPublisherPosts(filters?: FiltersInterface): Observable<PropertyPostListInterface>;

    abstract postProperty(property: AddPostDto): Observable<any>;

    abstract putProperty(id: string, property: Partial<AddPostDto>): Observable<any>;

    abstract addPicturesToPost(postId: string, filesNames: string[], userId?: string): Observable<any>;

    abstract updatePicturesToPost(postId: string, filesNames: { id: string, name: string }[], userId?: string): Observable<any>;

    abstract activatePost(postId: string, active: boolean): Observable<any>

    abstract publishPost(postId: string): Observable<any>

    abstract approvePost(postId: string): Observable<any>

    abstract rejectPost(postId: string): Observable<any>

    abstract deletePost(postId: string): Observable<any>

    abstract requestPublish(postId: string): Observable<any>
    
    abstract createOrUpdateLog(user_id:string,post_id:string, message:string): Observable<any>
}
