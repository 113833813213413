import {Component, Input, OnDestroy, OnInit, inject} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Subscription, take} from "rxjs";

import {FirebaseService} from '../../services/firebase.service';
import {AuthService} from "../../services/auth.service";
import {LnDialogComponent} from "../ln-dialog/ln-dialog.component";
import {TranslationService} from "../../services/translation.service";
import {locale as spanishLang} from './i18n/es';
import { TokenService } from '../../services/token.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() show: boolean = true;
    public showMenu: boolean = false;
    icon = 'menu';
    isLogin = false;
    subscriptions: Subscription[] = [];
    role!: string;
    tokenService = inject(TokenService)
    constructor(
        private _dialog: MatDialog,
        private router: Router,
        private firebaseService: FirebaseService,
        private auth: AuthService,
        private translate: TranslationService
    ) {
    }

    ngOnInit(): void {
        this.translate.addTranslation(spanishLang)
        const subscription = this.firebaseService.isAuthenticatedUser().subscribe(user => {
                this.isLogin = user;
                this.auth.isLogin = user;
            }
        )
        this.subscriptions.push(subscription);
        this.role = this.tokenService.getTypeUser()
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });

        this.subscriptions = [];
    }

    public showMenuMobile() {
        this.showMenu = !this.showMenu;
        this.icon = this.showMenu ? 'close' : 'menu';
    }

    public goToHome() {
        this.router.navigateByUrl('/sessions/signin');
    }

    public signOut() {
        this.firebaseService.doLogout();
        this.goToHome()
    }

    openDialogCloseSession() {
        this._dialog.open(LnDialogComponent, {
            width: '380px',
            data: {
                title: '¿Estás seguro que quieres cerrar sesión?',
                buttonOk: 'Si, cerrar sesión',
                buttonCancel: 'No, volver'
            }
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                if (res)
                    this.signOut()
            })
    }
}
