import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isLogin = false;

    constructor(
        private _http: HttpClient
    ) {
    }

    forgotPassword(email: string, captcha: string) {
        const headers = {
            'X-Recaptcha-Token': captcha
        }
        return this._http.post<any>(
            `${environment.baseURL}auth/reset-password`,
            {email}, {headers}
        )
    }
}
