import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {PropertyPostRepository} from "../repositories/property-post.repository";
import {PropertyPostListInterface} from "../../shared/interfaces/property-post-list.interface";
import {PropertyPostModel} from "../domain/property-post-model";
import {AddPostDto} from "../../shared/interfaces/add-post";
import {FiltersInterface} from "../../shared/interfaces/filters.interface";

@Injectable({
    providedIn: 'root'
})
export class PropertyPostUseCaseService {
    constructor(private repository: PropertyPostRepository) {
    }

    getPostDetails(id: string): Observable<PropertyPostModel> {
        return this.repository.getPostDetails(id);
    }

    getPublisherPosts(filters?: FiltersInterface): Observable<PropertyPostListInterface> {
        return this.repository.getPublisherPosts(filters);
    }

    postProperty(property: AddPostDto): Observable<any> {
        return this.repository.postProperty(property);
    }

    putProperty(id: string, property: Partial<AddPostDto>): Observable<any> {
        return this.repository.putProperty(id, property);
    }

    addPicturesToPost(postId: string, filesNames: string[], userId?: string): Observable<any> {
        return this.repository.addPicturesToPost(postId, filesNames, userId);
    }

    updatePicturesToPost(postId: string, filesNames: { id: string, name: string }[], userId?: string): Observable<any> {
        return this.repository.updatePicturesToPost(postId, filesNames);
    }

    activatePost(postId: string, active: boolean): Observable<any> {
        return this.repository.activatePost(postId, active);
    }

    publishPost(postId: string): Observable<any> {
        return this.repository.publishPost(postId)
    }

    approvePost(postId: string): Observable<any> {
        return this.repository.approvePost(postId)
    }

    rejectPost(postId: string): Observable<any> {
        return this.repository.rejectPost(postId)
    }

    deletePost(postId: string) {
        return this.repository.deletePost(postId);
    }

    requestPublish(postId: string): Observable<any> {
        return this.repository.requestPublish(postId);
    }

    createOrUpdateLog(user_id:string,post_id:string, message:string){
        return this.repository.createOrUpdateLog(user_id,post_id,message);
    }
}
