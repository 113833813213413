import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Loader } from "@googlemaps/js-api-loader"; // Importa la clase Loader desde el paquete @googlemaps/js-api-loader
import { DialogService } from "../../services/dialog.service";
import { GoogleMapsLoaderService } from "../../services/googleMapsLoader.service";

@Component({
	selector: "app-maps-autocomplete",
	templateUrl: "./maps-autocomplete.component.html",
	styleUrls: ["./maps-autocomplete.component.css"],
})

export class MapsAutocompleteComponent implements OnInit {
	// Define un FormGroup para gestionar el formulario
	formGroup = new FormGroup({
		address: new FormControl(""),
		latitude: new FormControl(""),
		longitude: new FormControl(""),
	});

	// Obtiene la clave de la API de Google Maps desde el entorno
	mapskey = environment.googleMapApiKey;

	// Observable para comprobar si la API de Google Maps se ha cargado correctamente
	apiLoaded$!: Observable<boolean>;

	// Elemento de referencia ViewChild para el campo de entrada
	@ViewChild("inputField") inputField!: ElementRef;

	// Propiedades de entrada y salida
	@Input() placeholder: string = "Buscar Por Dirección";
	@Output() locationSelected = new EventEmitter<{
		latitude: number;
		longitude: number;
		address: string;
		city: string;
		country: string;
	}>();

	// Variables para gestionar el mapa y la ubicación
	map!: google.maps.Map | null;
	autocomplete: google.maps.places.Autocomplete | undefined;
	defaultLatitude: number = 14.623075; // Latitud predeterminada para Ciudad de Guatemala
	defaultLongitude: number = -90.513314; // Longitud predeterminada para Ciudad de Guatemala
	latitude: number = 14.623075; // Latitud inicial
	longitude: number = -90.513314; // Longitud inicial
	disableTextInputs: boolean = false;
	disableLatLang: boolean = false;
	address: string = "";
	existingMarker: any;
	isLoading = true;
	city: string = "";
	country: string = "";

	constructor(private _dialogService: DialogService, private googleMapsLoaderService: GoogleMapsLoaderService) {
		// Carga la API de Google Maps y muestra el mapa
		this._apiLoading();
		this.loadMap();
	}

	ngOnInit() { }

	// Método privado para cargar la API de Google Maps
	private async _apiLoading(): Promise<boolean> {
		try {
			await this.googleMapsLoaderService.loadApi();
			this.showMap(this.defaultLatitude, this.defaultLongitude);
			return true;
		} catch (error) {
			this.handleError(error);
			return false;
		}
	}
	ngOnDestroy() {
		// Remove any reference to Google Maps objects
		this.map = null;

		// Try to remove the DOM element that contains the map
		const mapElement = document.getElementById('map');
		if (mapElement) {
			mapElement.remove();
		}

		// Remove the script tag that loads the Google Maps API
		const scriptElement = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
		if (scriptElement) {
			scriptElement.remove();
		}
	}

	// Método para cargar el mapa
	async loadMap() {
		// Importa la clase AdvancedMarkerElement
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker"
		)) as google.maps.MarkerLibrary;
		this.isLoading = true;

		// Comprueba si la API se ha cargado y carga el mapa
		this._apiLoading().then((loaded) => {
			this.isLoading = false;
			if (loaded) {
				// Configura las opciones del autocompletado de direcciones
				const autocompleteOptions: google.maps.places.AutocompleteOptions = {
					types: ["address"],
					componentRestrictions: { country: ["gt", "cr", "mx", "us"] },
				};

				// Inicializa el autocompletado de direcciones
				this.autocomplete = new google.maps.places.Autocomplete(
					this.inputField.nativeElement,
					autocompleteOptions
				);

				// Escucha los cambios en la selección de lugares
				this.autocomplete.addListener("place_changed", async () => {
					// Maneja la selección de lugar
					this.handlePlaceSelection();
				});
				console.log("Google Maps API loaded successfully");
			}
		}).catch((error) => this.handleError(error));
	}

	// Método para manejar la selección de lugar
	async handlePlaceSelection() {
		if (this.existingMarker) {
			this.existingMarker.setMap(null);
		}
		const place = this.autocomplete?.getPlace();
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker"
		)) as google.maps.MarkerLibrary;

		if (place) {
			const latitude = place?.geometry?.location?.lat() ?? 0;
			const longitude = place?.geometry?.location?.lng() ?? 0;
			let route = "";
			let streetNumber = "";
			if (place.address_components)
				place.address_components.forEach((component) => {
					if (component.types.includes("route")) {
						route = component.long_name;
					}
					if (component.types.includes("street_number")) {
						streetNumber = component.long_name;
					}
					this.address = `${route} ${streetNumber}`;
					if (component.types.includes("locality")) {
						this.city = component.long_name;
					}

					if (component.types.includes("country")) {
						this.country = component.long_name;
					}
				});

			this.latitude = latitude;
			this.longitude = longitude;
			const position = { lat: latitude, lng: longitude };
			const map = this.map;
			if (this.map) {
				// Crea un marcador en el mapa
				const marker = new AdvancedMarkerElement({
					map,
					position: position,
				}) as unknown as google.maps.Marker;
				this.map.setCenter({ lat: latitude, lng: longitude });
				this.map.setZoom(16);
				
				this.existingMarker = marker;
				this.disableLatLang = true;
				// Emite la ubicación seleccionada
				this.locationSelected.emit({
					latitude: this.latitude,
					longitude: this.longitude,
					address: this.address,
					city: this.city,
					country: this.country
				});
			}
		}
	}

	// Método para mostrar el mapa
	async showMap(latitude: number, longitude: number, zoom: number = 10) {
		const { Map } = (await google.maps.importLibrary(
			"maps"
		)) as google.maps.MapsLibrary;
		this.map = new Map(document.getElementById("map") as HTMLElement, {
			center: { lat: latitude, lng: longitude },
			zoom: zoom,
			mapId: "createUserMap",
		});
		this.markerClick();
	}

	// Método para obtener la dirección a partir de las coordenadas
	getAddressFromLatLng() {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode(
			{ location: { lat: this.latitude, lng: this.longitude } },
			(results, status) => {
				if (status === "OK") {
					if (results && results[0]) {
						this.inputField.nativeElement.value = results[0].formatted_address;
						let route = "";
						let streetNumber = "";
						if (results[0].address_components)
							results[0].address_components.forEach((component) => {
								if (component.types.includes("route")) {
									route = component.long_name;
								}
								if (component.types.includes("street_number")) {
									streetNumber = component.long_name;
								}
								this.address = `${route} ${streetNumber}`;
								if (component.types.includes("locality")) {
									this.city = component.long_name;
								}

								if (component.types.includes("country")) {
									this.country = component.long_name;
								}
							});
						this.locationSelected.emit({
							latitude: this.latitude,
							longitude: this.longitude,
							address: this.address,
							city: this.city,
							country: this.country
						});
					} else {
						console.log("No results found");
					}
				} else {
					console.log("Geocoder failed due to: " + status);
				}
			}
		);
	}
	async updateMap() {
		const map = this.map;
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker"
		)) as google.maps.MarkerLibrary;
		const { Map } = (await google.maps.importLibrary(
			"maps"
		)) as google.maps.MapsLibrary;

		if (map) {
			// Establece el zoom del mapa
			map.setZoom(16);

			// Obtiene la posición actual
			const position = { lat: this.latitude, lng: this.longitude };

			// Elimina el marcador existente
			if (this.existingMarker) {
				this.existingMarker.setMap(null);
			}

			// Centra el mapa en la nueva posición
			map.setCenter(position);

			// Crea un nuevo marcador en la nueva posición
			const marker = new AdvancedMarkerElement({
				map,
				position: position,
			}) as unknown as google.maps.Marker;

			// Actualiza el marcador existente
			this.existingMarker = marker;
		}

		// Obtiene la dirección correspondiente a las coordenadas
		this.getAddressFromLatLng();
	}


	// Método para manejar clics en el marcador
	async markerClick() {
		const map = this.map;
		const { AdvancedMarkerElement } = (await google.maps.importLibrary(
			"marker"
		)) as google.maps.MarkerLibrary;
		if (map) {
			map.addListener("click", (event: google.maps.MapMouseEvent) => {
				if (event.latLng) {
					if (this.existingMarker) {
						this.existingMarker.setMap(null);
					}

					this.latitude = parseFloat(event.latLng.lat().toString());
					this.longitude = parseFloat(event.latLng.lng().toString());
					const position = { lat: this.latitude, lng: this.longitude };

					const marker = new AdvancedMarkerElement({
						map,
						position: position,
					}) as unknown as google.maps.Marker;
					map.setCenter(position);
					map.setZoom(16);
					this.getAddressFromLatLng();

					this.locationSelected.emit({
						latitude: this.latitude,
						longitude: this.longitude,
						address: this.address,
						city: this.city,
						country: this.country
					});

					this.existingMarker = marker;
				}
			});
		}
	}

	// Método para manejar errores
	handleError(error: any) {
		this._dialogService.openInfoDialog(
			'ERROR',
			'',
			'Tuvimos un error al cargar ciertos componentes.',
			'Recargar página'
		).subscribe(() => window.location.reload());
		console.error("Error loading Google Maps script", error);
	}

	// Método para restablecer el mapa
	resetMap() {
		this.disableTextInputs = false;
		this.disableLatLang = false;
		this.latitude = this.defaultLatitude
		this.longitude = this.defaultLongitude
		this.showMap(this.defaultLatitude, this.defaultLongitude);
		this.inputField.nativeElement.value = "";
	}

	// Método para seleccionar la ubicación
	selectLocation() {
		const address = this.address;
		this.locationSelected.emit({
			latitude: this.latitude,
			longitude: this.longitude,
			address: address,
			city: this.city,
			country: this.country
		});
	}
}
