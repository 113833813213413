<button mat-icon-button [matMenuTriggerFor]="beforeMenu">
    <img class="icon" ngSrc="../../../../assets/icons/menu%20vertical.svg" alt="menu" height="39" width="39">
</button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
    <button mat-menu-item
            *role="['EXECUTIVE', post.property.status == 'PENDING' || post.property.status == 'REJECTED']"
            (click)="changePostStatus('TO_ACCEPTED')">
                <span>Aceptar</span>
    </button>
    <button mat-menu-item *role="'EXECUTIVE'" (click)="goToEdit()">
        <span>Modificar</span>
    </button>
    <button mat-menu-item *role="['EXECUTIVE', post.property.status == 'PENDING']" (click)="changePostStatus('TO_REJECTED')">
        <span>Rechazar</span>
    </button>
    <button mat-menu-item *role="['PUBLISHER', post.property.status == 'SAVED']" (click)="changePostStatus('TO_REQUEST_PUBLISH')">
        <span>Solicitar publicar</span>
    </button>
    <button mat-menu-item *ngIf="post.property.status == 'ACCEPTED'" (click)="changePostStatus('TO_ACTIVATED')">
        <span>{{ post.active ? 'Desactivar' : 'Activar' }}</span>
    </button>
    <button mat-menu-item *role="'EXECUTIVE'" (click)="deletePostConfirm()">
        <span>Eliminar</span>
    </button>
</mat-menu>
