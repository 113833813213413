import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {take} from "rxjs";

import {PropertyPostUseCaseService} from "../../../core/usecase/property-post-use-case.service";
import {PropertyPostModel} from "../../../core/domain/property-post-model";
import {LnDialogComponent} from "../ln-dialog/ln-dialog.component";


@Component({
  selector: 'app-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class ActionsMenuComponent {

  @Input({ required: true }) post!: PropertyPostModel;
  @Output() postStatusChange = new EventEmitter<{}>();
  @Output() deletePost = new EventEmitter<string>();

  constructor(
      private _router: Router,
      private _propertyPostUseCase: PropertyPostUseCaseService,
      private _dialog: MatDialog
  ) {
  }

  goToEdit() {
    this._router.navigateByUrl(`/my-account/posts/edit/${this.post.id}`)
  }

  changePostStatus(status: string) {
    this.postStatusChange.emit({postId: this.post.id, status: status});
  }

  deletePostConfirm() {
    this._dialog.open(LnDialogComponent, {
      width: '480px',
      data: {
        title: '¿Está seguro que desea eliminar esta publicación?',
        buttonOk: 'Sí, eliminar',
        buttonCancel: 'No, volver'
      }
    }).afterClosed()
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.deletePost.emit(this.post.id);
          }
        })
  }
}
