import {NgModule, isDevMode} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getApp, initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from "../environments/environment";
import {Interceptor} from "./shared/interceptors/token.interceptor";
import {SharedModule} from "./shared/shared.module";
import {NotFoundComponent} from './views/not-found/not-found.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GoogleMapComponent } from './shared/ln-google-map/google-map/google-map.component';


@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        DragDropModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth(getApp())),
        provideFirestore(() => getFirestore(getApp())),
        provideStorage(() => getStorage(getApp())),
        SharedModule,
        FormsModule,
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument(
            {maxAge: 25, logOnly: !isDevMode()}
        ),
        EffectsModule.forRoot([]),
        TranslateModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
