import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter, inject,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Router } from "@angular/router";
import { take } from "rxjs";

import { AuthService } from "../../../services/auth.service";
import { DialogService } from "../../../services/dialog.service";
import { PropertyPostUseCaseService } from "../../../../core/usecase/property-post-use-case.service";
import { PropertyPostModel } from "../../../../core/domain/property-post-model";
import { ModalService } from "../../../services/modal.service";
import { FirebaseService } from "../../../services/firebase.service";
import { TokenService } from "../../../services/token.service";
import { MatDialog } from "@angular/material/dialog";
import { PublisherPostsComponent } from 'src/app/views/my-account/publisher-posts/publisher-posts.component';
import { RealStateUseCaseService } from 'src/app/core/usecase/real-state-use-case.service';

@Component({
    selector: 'ln-card',
    templateUrl: './ln-card.component.html',
    styleUrls: ['./ln-card.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnCardComponent implements OnInit {

    @Input() post!: PropertyPostModel
    @Input() showDeleteOption = false;
    @Output() favoritePostDeleted = new EventEmitter<Boolean>();
    @Input() showDeactivatedPost = false;
    @Output() postDeleted = new EventEmitter<string>();
    tokenService = inject(TokenService)
    role!: string;
    localUserId: any;


    constructor(
        private _auth: AuthService,
        private _dialogService: DialogService,
        private _firebaseService: FirebaseService,
        private _postService: PropertyPostUseCaseService,
        private _router: Router,
        private _modal: ModalService,
        private _dialog: MatDialog,
        private _publisherCaseService: RealStateUseCaseService
    ) {
    }

    ngOnInit() {
        this.localUserId = localStorage.getItem('client_id')
        this.role = this.tokenService.getTypeUser()
        
    }

    openIframeDialog(path: string) {
        this._dialogService.openIframeDialog(path)
            .pipe(take(1))
    }

    sendPostStatusChange(data: any) {
        if (data.status == 'TO_REQUEST_PUBLISH') {
            this._postService.requestPublish(this.post.id)
                .pipe(take(1))
                .subscribe({
                    next: (data) => {
                        this.post.property.status = 'PENDING';
                        this._modal.show(
                            "",
                            "La publicación ha sido enviada a los ejecutivos de Luna Nueva",
                            "SUCCESS", 5
                        )
                    },
                });
        }
        else if (data.status == 'TO_ACTIVATED') {
            this.post.active = !this.post.active;
            this._postService.activatePost(this.post.id, this.post.active)
                .pipe(take(1))
                .subscribe({
                    next: (data) => {
                        this.post.active = data['active'];
                        this._modal.show(
                            '',
                            `La publicación ha sido ${this.post.active ? ' activada ' : ' desactivada '} con éxito`,
                            "SUCCESS", 5
                        )
                    }
                });
        } else if (data['status'] == 'TO_ACCEPTED') {
            this._postService.approvePost(data.postId)
                .pipe(take(1))
                .subscribe({
                    next: (data) => {
                        this.post.active = true;
                        this._modal.show(
                            "",
                            "La publicación ha sido aprobada con éxito",
                            "SUCCESS", 5
                        )
                    }
                });
        } else if (data['status'] == 'TO_REJECTED') {
            this._postService.rejectPost(data.postId)
                .pipe(take(1))
                .subscribe({
                    next: (data) => {
                        this.post.property.status = "REJECTED";
                        this._modal.show(
                            "",
                            "La publicación ha sido rechazada",
                            "SUCCESS", 5
                        )
                    }
                });
        }

    }

    deletePost(postId: string) {
        const message = 'Deleted - Title: '+ this.post.title
        this._postService.createOrUpdateLog(this.localUserId, postId, message)
            .subscribe({
                next: (data) => {

                },
                error: (error) => {
                    console.error(error);
                }
            });
        this._postService.deletePost(postId)
            .pipe(take(1))
            .subscribe({
                next: (data) => {
                    this.postDeleted.emit(postId);
                    this._modal.show(
                        "",
                        "La publicación ha sido eliminada",
                        "SUCCESS", 5
                    )
                },
                error: () => {
                    this._modal.show(
                        "",
                        "Ocurrió un error inesperado. Por favor intente nuevamente",
                        "ERROR", 5
                    )
                }
            });
    }
}
